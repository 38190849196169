.project-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #ffffff;
}

h2 {
  border-bottom: 2px solid #00ff00;
  padding-bottom: 10px;
}

.screenshot {
  width: 100%;
  max-width: 600px;
  height: auto;
  display: block;
  margin: 20px auto;
}

section {
  margin: 40px 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 10px 0;
}

p {
  line-height: 1.6;
}