/* index.css */
body {
  margin: 0;
  padding: 0;
  background-color: black;
  color: green;
}

@media (max-width: 768px) {
  .navbar a {
    font-size: 1em;
  }

  .header {
    font-size: 2em;
  }

  .main-content {
    padding: 10px;
  }

  .certification-img {
    width: 80px;
  }
}