body {
  margin: 0;
  padding: 0 10px;
  /* Add padding to the body */
  font-family: 'Courier New', Courier, monospace;
  background-color: #000;
  color: #ffffff;
  /* Change text color to white */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  /* Ensure the body does not exceed the viewport width */
  overflow-x: hidden;
  /* Prevent horizontal scrolling */
}

a {
  color: #00ff00;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

header {
  background-color: #000;
  padding: 20px;
  text-align: center;
  width: 100%;
  z-index: 888;
  /* Lower z-index than dropdown-content */
  margin-top: 60px;
  /* Ensure no margin */
}

@media (max-width: 768px) {
  .App {
    padding-top: 100px;
    /* Adjust the margin for smaller screens */
  }
}

footer {
  background-color: #111;
  padding: 20px;
  text-align: center;
  width: 100%;
  margin-top: auto;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  /* Ensure App does not exceed the viewport width */
}

.main-content {
  padding: 20px;
  flex: 1;
  width: 100%;
  /* Ensure main-content takes full width */
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  /* Ensure it does not exceed the viewport width */
}

.section {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* Center text */
  max-width: 800px;
  width: 100%;
}

.section h2 {
  border-bottom: 2px solid #00ff00;
  padding-bottom: 10px;
  width: 100%;
  text-align: center;
  /* Center text */
}

.certifications {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  /* Center certifications horizontally */
}

.certification {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.certification img {
  max-width: 100px;
  margin-bottom: 10px;
}

.certification p {
  font-weight: bold;
  /* Make certification titles bold */
}

ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

ul li {
  margin: 10px 0;
}

@media (max-width: 768px) {
  .section {
    padding: 0 10px;
    /* Add padding for smaller screens */
  }
}

.project-detail {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.project-detail h2 {
  text-align: center;
  border-bottom: 2px solid #00ff00;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.project-detail h3 {
  margin-top: 20px;
}

.project-detail ol {
  margin-left: 20px;
}