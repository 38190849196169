/* Navbar Styling */
.navbar {
  background-color: #111;
  padding: 10px;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
}

.navbar a,
.dropbtn {
  color: #00ff00;
  background-color: #111;
  margin: 5px 10px;
  text-decoration: none;
  font-size: 1em;
  font-weight: normal;
  border: none;
  cursor: pointer;
}

.navbar a:hover,
.dropbtn:hover {
  text-decoration: underline;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #111;
  min-width: 160px;
  z-index: 1001;
  border: 1px solid #00ff00;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: #00ff00;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #333;
}

/* Mobile Navbar */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-icon {
    display: block;
    color: #00ff00;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .nav-menu.active {
    height: auto;
  }

  .nav-item {
    width: 100%;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: block;
  }

  .nav-links:hover {
    background-color: #555;
  }
}